.flexx {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-left: 10%;
}
.getFlexed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  padding-left: 10%;
}
.shopper {
  font-size: medium;
  font-weight: bold;
  font-family: fantasy;
}
.bannerr {
  background-color: rgba(255, 0, 0, 0.7);
  text-align: center;
  padding: 7.5px 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 0px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
hr {
  width: 75%;
}
h2 {
  width: 80%;
  padding-left: 10%;
}
p {
  font-weight: 400;
  text-align: left;
  max-width: 80%;
  padding-left: 10%;
}
.notp {
  font-weight: 400;
  text-align: left;
  max-width: 80%;
  padding-left: 0%;
}
.reviewp {
  max-width: 95%;
  text-align: left;
}
.redd {
  color: red;
  font-size: 10px;
}

.logo {
  max-width: 150px;
  float: left;
}
h3,
h4,
li {
  text-align: left;
  padding-left: 10%;
}
.ButtonAir {
  width: 250px;
}
.boldbig {
  font-size: 18px;
}
.contactcard {
  height: 450px;
  width: 100%;
  overflow: scroll;
}
.contactinfo {
  width: 88%;
  margin-left: 6%;
}

.submitted {
  font-size: 25px;
  width: 60%;
  border-radius: 5%;
}
.emailicon {
  display: none;
}

div#wrap {
  margin: 0 auto;
  width: 90%;
  background-color: rgba(240, 255, 192);
  padding: 50px;
}
iframe {
  border: 1px Solid Black;
}
table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 80%;
  margin-left: 10%;
}

td,
th {
  border: thin solid black;
  padding: 5px;
  width: 25%;
}

div.slide {
  height: 500px;
  max-width: 960px;
  overflow: hidden;
}
div.bold {
  display: inline;
  font-weight: bold;
}
.alert {
  background-color: rgb(251 185 185);
  padding: 25px;
  border-bottom: 1px solid black;
}
.snowflake {
  position: fixed;
  top: 0px;
  left: 0px;
}
a.button {
  padding: 25px;
  background: #ffa722;
  color: black;
  text-decoration: none;
  cursor: pointer;
  float: right;
}
.card-panel {
  width: 80%;
  margin-left: 10%;
  text-align: left;
  border-radius: 1%;
}
.carousel {
  width: 80%;
  margin-left: 10%;
}
.carousel .carousel-item {
  width: 400px !important;
  height: 350px !important;
  max-height: 350px !important;
}
.carousel {
  min-height: 400px;
}
img {
  border-radius: 5%;
  max-height: 285px;
  max-width: 350px;
  display: inline;
}

@media only screen and (max-width: 800px) {
  p {
    font-weight: 400;
    text-align: left;
    max-width: 95%;
    padding-left: 0%;
  }

  h2,
  h3,
  h4,
  li {
    text-align: center;
    padding-right: 12%;
    width: 100%;
  }
  table {
    width: 100%;
    margin-left: 0%;
  }
  .card-panel {
    width: 120%;
    margin-left: -10%;
    text-align: left;
  }
  .flexx {
    display: flex;
    flex-direction: column;
    /* display: inline; */
    width: 100%;
    padding-left: 0%;
  }
  .ButtonAir {
    font-size: 10px;
    width: 225px;
    margin-left: 10%;
  }
  .logo {
    left: 50%;
  }
  h1 {
    width: 80%;
    font-size: 30px;
    padding-left: 0%;
  }
  .contactcard {
    height: auto;
  }
  .contactinfo {
    width: 100%;
    margin-left: 0%;
  }
  .submitted {
    font-size: 25px;
    width: 90%;
  }
  img {
    width: 80%;
  }
  .getFlexed {
    display: flex;
    flex-direction: column;
    align-self: auto;
    width: 80%;
    padding-left: 10%;
  }
  .shopper {
    font-size: small;
    font-weight: bold;
  }
  .boldbig {
    font-size: 13px;
  }
  hr {
    width: 90%;
  }
  div#wrap {
    margin: 0 auto;
    background-color: rgba(240, 255, 192, 0.3);
    /* padding: 50px; */
    width: 97.5%;
  }
  .boldbig {
    font-size: 12px;
  }
}
