.home {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: auto;
  /* padding-left: 0.4%;
  padding-right: 0.4%; */
  padding-top: 0.2%;
  padding-bottom: 0.4%;
  overflow: auto;
  font-family: calibri, helvetica, arial, sans-serif;
}

.sidenav {
  background-color: #dcdcdc;
  font-size: 18px;
  margin-top: 56px;
  font-size: bold;
}

@media only screen and (max-width: 600px) {
  .home {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 95vh;
    /* padding-left: 0%;
    padding-right: 0%;
    padding-top: 0%;
    padding-bottom: 0%; */
    overflow: auto;
    font-family: calibri, helvetica, arial, sans-serif;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 20px;
  }
}
