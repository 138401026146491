html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* needed for container min-height */
  /* background: gray; */

  /* font-family: arial, sans-serif;
  font-size: small;
  color: #666; */
  /* font-family: "Oxygen", sans-serif; */
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}
.centerfot {
  padding-bottom: 15px;
}
.ButtonAir2 {
  width: 225px;
  height: 50px;
  /* font-size: 5px; */
}

.nav-wrapper {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  font-weight: bold;
  /* width: 100%; */
  display: block;
  z-index: 1030;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.brand-logo {
  display: none;
}
p {
  width: 100%;
}
.hide-on-med-and-down a:hover:not(.active) {
  background-color: rgba(240, 255, 192, 0.5);
  color: black;
}
.centerfot {
  padding-bottom: 15px;
}

.tab {
  background: none;
  color: white;
  overflow: hidden;
  text-align: center;
  /* flex-grow: 1; */
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.tab.active {
  background: rgba(240, 255, 192);
  color: black;
}

.submitted {
  font-size: 25px;
  width: 60%;
  /* background-color: lightgreen; */
  border-radius: 5%;
  /* padding-top: 30%; */
}
